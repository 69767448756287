@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap");

@tailwind base;
@tailwind components;

@font-face {
  font-family: "graphik-bold";
  src: url("./fonts/GraphikBold.otf");
}
@font-face {
  font-family: "graphik-semibold";
  src: url("./fonts/GraphikSemibold.otf");
}
@font-face {
  font-family: "graphik-medium";
  src: url("./fonts/GraphikMedium.otf");
}
@font-face {
  font-family: "graphik-regular";
  src: url("./fonts/GraphikRegular.otf");
}
@font-face {
  font-family: "graphik-light";
  src: url("./fonts/GraphikLight.otf");
}

::-webkit-input-placeholder {
  font-family: "graphik-light", sans-serif;
  font-size: 12px;
  color: #707070;
}

:-moz-placeholder {
  font-family: "graphik-light", sans-serif;
  text-align: left;
  font-size: 12px;
  color: #707070;
}

@tailwind utilities;
